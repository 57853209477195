import { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Grid } from '@mui/material'
import Lottie from 'react-lottie'
import * as animationData from './clock.json'
import MaterialModal from '..'
import useLabels from '../../../hooks/use-labels'
import ContentContainer from '../content-container'
import CustomButton from '../../custom-button'
import MaterialModalHeader from '../material-modal-header'
import {
  logoutUser,
  setLoginUser
} from '../../../../src/store/user/user-reducer'
import { refreshToken } from '../../../store/user/user-action'
import { secondsToHms } from '../../../utils/common-utils'
import FlexButtons from '../../flex-button'
import { color } from '@mui/system'
const SessionTimeoutModal = (props: any) => {
  const { isOpen, onClose } = props
  const [time, setTime] = useState(59)
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   let interval: any
  //   interval = setInterval(() => {

  //     if (time > 0) {

  //       setTime((prev: any) => prev - 1)

  //     } 
  //     else {
  //       clearInterval(interval)
  //       handleLogout()


  //     }
  //   }, 1000)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [time])
  useEffect(() => {
    let frameId: number;
    let intervalStartTime: number = Date.now();
    const updateCountdown = () => {
      const elapsedTime = Date.now() - intervalStartTime;
      if (elapsedTime >= 1000) {
        const secondsElapsed = Math.floor(elapsedTime / 1000);
        setTime((prev: any) => prev - secondsElapsed);
        intervalStartTime += secondsElapsed * 1000;
      }
      if (time > 0) {
        frameId = window.requestAnimationFrame(updateCountdown);
      } else {
        handleLogout();
      }
    };
    frameId = window.requestAnimationFrame(updateCountdown);
    return () => {
      window.cancelAnimationFrame(frameId);
    };
  }, [time]);
  
  
  const handleLogout = () => {
    localStorage.setItem("isLoggedIn",'false')
     dispatch(logoutUser())
     navigate('/')
     
     onClose()
  }


  const handleStay = () => {
    refreshToken(activeUser.refresh_token)
      .then((response) => {
        dispatch(setLoginUser(response))        
      })
      .catch(console.error)
    onClose()
  }


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  
  

  return (
    <MaterialModal
      boxClass="modal-width"
      open={isOpen}
      handleClose={onClose}
      width={'30rem'}
    >
      <MaterialModalHeader titleColor='#05315F' title={"Session Expiry Alert!"} onClose={onClose} />
      
      <ContentContainer className="full-height">
        {/* <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          className="grid-height pt-3 "
        >
          <Grid item xs={12} className=" p-1"> */}
            {/* <div className="d-flex"> */}
              {/* <div className="justify-center allign-center">
                <Lottie options={defaultOptions} height={40} width={40} />
              </div> */}
              <div className="mb-3 mt-2">
                <p className="text-sm">{"Your session is about to expire. You will be logged out in"}</p>
              </div>
            {/* </div> */}
            <div className="d-flex-col text-md weight-600 text-red justify-center align-center">
              {time && secondsToHms(time)}
            </div>
          {/* </Grid>
          </Grid> */}

          <FlexButtons className="layout-buttons mb-3 pt-4">
              <CustomButton
                type="primary"
                text={"Stay"}
                style= {{width: '5.188rem',height:' 2.25rem' }}
                className="header-button-width header-button-height text-sm"
                onClick={handleStay}
              />
              <CustomButton
                type="outline"
                text={"Logout"}
                style= {{width: '5.188rem',height:' 2.25rem' }}
                className="header-button-width header-button-height text-sm "
                onClick={handleLogout}
              />
          </FlexButtons>
        
      </ContentContainer>
    </MaterialModal>
  )
}
export default SessionTimeoutModal

