import moment from 'moment'
import {
  capitalize,
  dateTimeFormating,
  formatCurrency
} from '../../../../../../utils/common-utils'
import MCCIcons from '../../../../../../utils/MCCIcons'
import Details from '../../assets/ic_newDetails.svg'
import Pending from '../../assets/ic_pending.svg'


export const prepareHistoryDetails = (
  transactions: any,
  { onTransactionClick }: any
) => {
  return transactions.map((item: any) => {
    const {
      transactionDatetime,
      cardLastFour,
      isoMessageType,
      transactionType,
      amount,
      description,
      currencyCode,
      transactionId,
      additionalData: { feeAmount, memo, transactionImpact },
      state,
      merchant,
      runningBalance,
      additionalData,
      merchant: { mccGroup }
    } = item


    const stateTrans = state;

    const date = dateTimeFormating(transactionDatetime, 'MMM DD YYYY');
    const preAuth = (isoMessageType == '0100' || isoMessageType == '0120') ? true : false;

    const showBalance = (preAuth && stateTrans.toLowerCase() == 'pending') ? false : true;
    const isCredit = additionalData.transactionImpact.toLowerCase() === 'credit';
    const merchantName = merchant.merchantName;
    const icon = mccGroup ? <img src={`${MCCIcons[mccGroup] || MCCIcons.default}`} /> : <img src={`${MCCIcons.default}`} />

    const transactionAmount: any = {
      "true": <p className='weight-500' style={{ color: "#228B22" }}>{formatCurrency(amount, currencyCode)}</p>,
      "false": <p className='weight-500' style={{ color: "#FF0000" }}>-{formatCurrency(amount * -1, currencyCode)}</p>
    }
    const desc = description || memo || null;

    return {

     
      date,
      icon: (
        <>


     
            <p className='text-truncate' style={{ paddingLeft: '5px', paddingTop: '2px', maxWidth: '300px' }} title={`${desc ? desc : ''}  ${merchantName ? '- ' + merchantName : ''}`}>{desc ? desc  : ''}  {merchantName ? '- ' + merchantName : ''}</p>
        
        </>
      ),
      // transactionType: capitalize(transactionType),
      amount: transactionAmount[isCredit.toString()],
      runningBalance: showBalance ? formatCurrency(runningBalance, currencyCode) : '-',
      action: preAuth ?  <p
      className="status"
      style={{
        width:'6rem',
        backgroundColor: '#F29C55' }}
    > Pending </p>  : '',
      detail: <img className='pointer underline  '  style={{marginLeft:'12px'}}  onClick={() => onTransactionClick(item)} title="Details" src={Details} alt="Details Image" />,

    }
  })
}
