import { useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { getDecimalPlacesForCurrency } from 'currency-decimal-places'

import useLabels from '../../../../../hooks/use-labels'
import useStyles from '../../../../../hooks/use-styles'
import {
  decimalCount,
  formatCurrency,
  formatName,
  padLastFour
} from '../../../../../utils/common-utils'
import { errorMessage } from '../../../../../utils/validation'
import CustomButton from '../../../../custom-button'
import FlexButtons from '../../../../flex-button'
import FormField from '../../../../form/form-field'


interface EnterAmountProps {
  formData: any
  onClose: () => void
  onFormSubmit: (values: any) => void
  recipient: any
  SecondaryTransfer: boolean

}

const EnterAmount = (props: EnterAmountProps) => {
  const { formData, recipient, onClose, onFormSubmit ,SecondaryTransfer} = props
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)
  const { cards } = cardholder;

  const [fieldValues, setFormData] = useState({
    amount: formData.amount || '',
    account: formData.account || '',
    secondaryAccount: formData.secondaryAccount || ''
  })



  const [fieldErrors, setFieldErrors] = useState({}) as any

  const {
    cancelLabel,
    enterTransferDetailLabel,
    continueLabel,
    availableBalanceLabel,
    selectAccountLabel,
    enterTransferAmountLabel
  } = useLabels([
    'cancelLabel',
    'enterTransferDetailLabel',
    'continueLabel',
    'availableBalanceLabel',
    'selectAccountLabel',
    'enterTransferAmountLabel'
  ])
  const { labelColor } = useStyles(['labelColor'])

  const prepareSecondaryCardOptions = (cards: any) => {
    return cards
      .filter(({ cardholder }: any) => cardholder.primaryCardholderId )
      .map((cardItem: any, index: number) => {
        const { firstName, lastName } = cardItem.cardholder
        return {
          name: `${padLastFour(cardItem.lastFour)} - ( ${formatName(
            firstName,
            lastName
          )} )`,

          value: cardItem
        }
      })
  }
  const preparePrimaryCardOptions = (cards: any) => {
    return cards
      .filter(({ cardholder }: any) => !cardholder.primaryCardholderId )
      .map((cardItem: any, index: number) => {
        const { firstName, lastName } = cardItem.cardholder
        return {
          name: `${padLastFour(cardItem.lastFour)} - ( ${formatName(
            firstName,
            lastName
          )} )`,

          value: cardItem
        }
      })
  }
  
  const   prepareCardOptions
  = (cards: any) => {
    return cards
      .filter(({ status }: any) => status.toLowerCase() === 'active')
      .map((cardItem: any, index: number) => {
        const { firstName, lastName } = cardItem.cardholder
        return {
          name: `${padLastFour(cardItem.lastFour)} - ( ${formatName(
            firstName,
            lastName
          )} )`,

          value: cardItem
        }
      })
  }

  const onFieldChange = (key: string, value: string) => {
    const errors = { ...fieldErrors }
    const values: any = { ...fieldValues }

    if (typeof value === 'string' && !value.trim()) {
      errors[key] = errorMessage.required
    } else if (key === 'account') {
      values.amount = ''
      delete errors.amount
      delete errors.account
    }
    else if (key === 'secondaryAccount') {
      values.amount = ''
      delete errors.secondaryAccount
      delete errors.secondaryAccount
    }
     else if (key === 'amount') {
      const { availableBalance, currencyCode } = account.balance
      const decimalPlaces = getDecimalPlacesForCurrency(currencyCode)

      if (+value > availableBalance) {
        errors[key] = errorMessage.maxAmountLimit
      } else if (+value <= 0) {
        errors[key] = errorMessage.minAmountLimit
      } else if (!Number(value)) {
        errors[key] = errorMessage.invalidNumber
      } else if (decimalCount(value) > decimalPlaces) {
        errors[key] = errorMessage.invalidDecimal
      } else {
        delete errors[key]
      }
    } else {
      delete errors[key]
    }

    values[key] = value

    setFieldErrors(errors)
    setFormData(values)
  }

  const validFields = () => {
    const errors = { ...fieldErrors }
    const requiredFields =  SecondaryTransfer ?  ['account', 'amount', 'secondaryAccount'] :  ['account', 'amount']
    let valid = true
    requiredFields.forEach((field) => {
      if (!fieldValues[field as keyof typeof fieldValues]) {
        errors[field] = errorMessage.required
        valid = false
      }
    })

    setFieldErrors(errors)
    return valid
  }

  const handleSubmit = () => {
    if (validFields()) {
      onFormSubmit(fieldValues)
    }
  }

  const { account,secondaryAccount } = fieldValues

  return (
    <div className="mat-modal-body">
      <div className="mb-4">
        <p className="weight-500 text-center" style={{ color: labelColor }}>
          {enterTransferDetailLabel}
        </p>
      </div>

      <div className="mt-2">
        <FormField
          type="select"
          elementProps={{
            name: 'account',
            label: SecondaryTransfer ?  'Select Primary Account': selectAccountLabel,
            options:SecondaryTransfer ? preparePrimaryCardOptions(cards) : prepareCardOptions(cards),
            nameKey: 'name',
            valueKey: 'value',
            required: true,
            // placeholder:  :'Select Account'  
          }}
          value={account}
          fieldMessage={
            account &&
            `${availableBalanceLabel}: ${formatCurrency(
              account.balance.availableBalance,
              account.balance.currencyCode
            )}`
          }
          onChange={(name: string, value: string) => onFieldChange(name, value)}
          error={fieldErrors.account}
        />
        {SecondaryTransfer &&(
        <FormField
          type="select"
          elementProps={{
            name: 'secondaryAccount',
            label: "Select Secondary Account",
            options: prepareSecondaryCardOptions(cards),
            nameKey: 'name',
            valueKey: 'value',
            required: true,
            placeholder: 'Select Secondary Account'
          }}
          value={secondaryAccount}
          fieldMessage={
            secondaryAccount &&
            `${availableBalanceLabel}: ${formatCurrency(
              secondaryAccount.balance.availableBalance,
              secondaryAccount.balance.currencyCode
            )}`
          }
          onChange={(name: string, value: string) => onFieldChange(name, value)}
          error={fieldErrors.secondaryAccount}
        />)}

        <FormField
          type="text"
          elementProps={{
            label: enterTransferAmountLabel,
            name: 'amount',
            placeholder: account
              ? formatCurrency(0, account.balance.currencyCode)
              : '0.00',
            required: true,
            disabled: !account
          }}
          value={fieldValues.amount}
          onChange={(name: string, value: string) => onFieldChange(name, value)}
          error={fieldErrors.amount}
        />
      </div>

      <FlexButtons className="mt-5 mb-5">
        <CustomButton
          className="weight-500 transparent-background"
          type="outline"
          text={cancelLabel}
          onClick={onClose}
          style={{ width: '9rem' }}
        />
        <CustomButton
          type="next"
          text={continueLabel}
          disabled={!!Object.keys(fieldErrors).length}
          style={{ width: '9rem' }}
          onClick={handleSubmit}
        />
      </FlexButtons>
    </div>
  )
}
export default EnterAmount
