import Agriculture from "../assets/mcg_icons/Agriculture.svg";
import Contractors from "../assets/mcg_icons/Contractors.svg";
import Miscellaneous from "../assets/mcg_icons/Miscellaneous.svg";
import AirLines from "../assets/mcg_icons/AirLines.svg";
import Hotels from "../assets/mcg_icons/Hotels.svg";
import Transportation from "../assets/mcg_icons/Transportation.svg";
import Telecom from "../assets/mcg_icons/Telecom.svg";
import ITandComputers from "../assets/mcg_icons/ITandComputers.svg";
import MoneyTransfers from "../assets/mcg_icons/MoneyTransfers.svg";
import Utilities from "../assets/mcg_icons/Utilities.svg";
import Vehicles from "../assets/mcg_icons/Vehicles.svg";
import HomeAndOffice from "../assets/mcg_icons/HomeAndOffice.svg";
import Healthcare from "../assets/mcg_icons/Healthcare.svg";
import Recreation from "../assets/mcg_icons/Recreation.svg";
import Wearables from "../assets/mcg_icons/Wearables.svg";
import Stores from "../assets/mcg_icons/Stores.svg";
import Marketing from "../assets/mcg_icons/Marketing.svg";
import fuels from "../assets/mcg_icons/Fuels.svg";
import FinancialInst from "../assets/mcg_icons/FinancialInst.svg";
import NonFinInst from "../assets/mcg_icons/NonFinInst.svg";
import PersonalCare from "../assets/mcg_icons/PersonalCare.svg";
import PublicSubsids from "../assets/mcg_icons/PublicSubsids.svg";
import Travels from "../assets/mcg_icons/Travels.svg";
import ShopsAndStores from "../assets/mcg_icons/ShopsAndStores.png";
import PicnicSpots from "../assets/mcg_icons/PicnicSpots.svg";
import Education from "../assets/mcg_icons/Education.svg";
import Social from "../assets/mcg_icons/Social.svg";
import Political from "../assets/mcg_icons/Political.svg";
import Religious from "../assets/mcg_icons/Religious.svg";
import GovtServices from "../assets/mcg_icons/GovtServices.svg";
import Professional from "../assets/mcg_icons/Professional.svg";
import CashDisbursements from "../assets/mcg_icons/CashDisbursements.png";
import Gambling from "../assets/mcg_icons/Gambling.png";
import CarRentals from "../assets/mcg_icons/CarRentals.png";
import RestaurantsAndBars from "../assets/mcg_icons/RestaurantsAndBars.png";
import de_fault from "../assets/mcg_icons/default.png";

const MCCIcons: any = {
  Agriculture: Agriculture,
  Contractors: Contractors,
  Miscellaneous: Miscellaneous,
  AirLines: AirLines,
  Hotels: Hotels,
  Transportation: Transportation,
  Telecom: Telecom,
  "IT&Computers":ITandComputers,
  MoneyTransfers: MoneyTransfers,
  Utilities: Utilities,
  Vehicles: Vehicles,
  HomeAndOffice: HomeAndOffice,
  Healthcare: Healthcare,
  Recreation: Recreation,
  Wearables: Wearables,
  Stores: ShopsAndStores,
  Marketing: Marketing,
  Fuels: fuels,
  FinancialInst: FinancialInst,
  NonFinInst: NonFinInst,
  PersonalCare: PersonalCare,
  PublicSubsids: PublicSubsids,
  Travels: Travels,
  PicnicSpots: PicnicSpots,
  ShopsAndStores: ShopsAndStores,
  Education: Education,
  Social: Social,
  Political: Political,
  Religious: Religious,
  GovtServices: GovtServices,
  Professional: Professional,
  CashDisbursements: CashDisbursements,
  Gambling: Gambling,
  CarRentals: CarRentals,
  RestaurantsAndBars: RestaurantsAndBars,
  ProfessionalServices: Professional, 
  default: de_fault,
};

export default MCCIcons;