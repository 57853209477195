import { Grid } from "@mui/material";
import { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useConfigs from "../../../../hooks/use-config";
import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import { phoneInstance } from "../../../../utils/common-utils";
import { namePhoneFields } from "../../../../utils/fields.utils";
import { errorMessage, fieldValidator } from "../../../../utils/validation";
import FormField from "../../../form/form-field";
import FormButtons from "../../form-buttons";
import SectionHeader from "../../header/section-header";

const PersonalPhoneName = (props: any) => {
  const { onFormSubmit, formData } = props;
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users);
  const { firstName, lastName, phoneNumber, countryDialingCode } = formData;
  // const countryDialingCode = 1;

  const [fieldValues, setFieldValues] = useState({
    firstName: firstName || "",
    lastName: lastName || "",
    phoneNumber: phoneNumber ? `+${countryDialingCode}${phoneNumber}` : "",
  }) as any;

  const [fieldErrors, setFieldErrors] = useState({}) as any;

  const {
    personalInformationTitle,
    personalPhoneNameDescription,
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
  } = useLabels([
    "personalInformationTitle",
    "personalPhoneNameDescription",
    "firstNameLabel",
    "lastNameLabel",
    "phoneNumberLabel",
  ]);

  const { subTitleColor } = useStyles(["subTitleColor"]);


  const { nameLength, patriotActNotice, patriotActNoticeBold } = useConfigs(["nameLength", "patriotActNotice", "patriotActNoticeBold"]);

  const fieldProps = {
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    nameLength,
  };

  const validate = (key: string, value: string, options?: any) => {
    let errors = { ...fieldErrors };
    if (!value.trim() && options.required) {
      errors[key] = errorMessage.required;
    } else {
      delete errors[key];
    }

    setFieldErrors(errors);
  };

  const onFieldChange = (key: string, value: string, options?: any) => {
   

    if (key === "phoneNumber") {
      validate(key, value, options);
      value.includes("+1")
        ? setFieldValues((prev: any) => ({ ...prev, [key]: value }))
        : setFieldValues((prev: any) => ({ ...prev, [key]: "1" }));
    } else {
      validate(key, value, options);
      setFieldValues((prev: any) => ({ ...prev, [key]: value }));
    }
  };

  const validFields = () => {
    const errors = { ...fieldErrors };
    const requiredFields = ["firstName", "lastName", "phoneNumber"];
    let valid = true;
    requiredFields.forEach((field) => {
      if (!fieldValues[field]) {
        errors[field] = errorMessage.required;
        valid = false;
      } else if (
        field === "phoneNumber" &&
        !fieldValidator.phoneNumber(fieldValues.phoneNumber)
      ) {
        errors[field] = errorMessage[field];
        valid = false;
      }
    });

    setFieldErrors(errors);
    return valid;
  };

  const onSubmit = () => {
    if (validFields()) {
      const phone = phoneInstance().parseAndKeepRawInput(
        fieldValues.phoneNumber,
        ""
      );

      onFormSubmit({
        ...fieldValues,
        countryDialingCode: phone.getCountryCode(),
        phoneNumber: phone.getNationalNumber().toString(),
        phoneType: "MOBILE",
      });
    }
  };

  return (
    <>
      <SectionHeader
        title={personalInformationTitle}
        subTitle={personalPhoneNameDescription}
      />

      {namePhoneFields(fieldProps).map((fieldItem) => {
        const { type, elementProps } = fieldItem;
        const { name, required } = elementProps;
        return (
          <Grid item md={12} key={name}>
            <FormField
              {...fieldItem}
              value={fieldValues[name]}
              hasError={false}
              onChange={(name: string, value: string) =>
                onFieldChange(name, value, { required, type })
              }
              error={fieldErrors[name]}
            />
          </Grid>
        );
      })}
      <p className="text-2xs text-left pt-2" style={{color:subTitleColor}}>
        <b>{patriotActNoticeBold}</b>
        {patriotActNotice}
      </p>

      <FormButtons
        handleNext={onSubmit}
        disableNext={Object.keys(fieldErrors).length}
        handlePrevious={props.handlePrevious}
        disablePrevious={!!activeUser.access_token}
        customClass={"mt-4"}
      />
    </>
  );
};

export default PersonalPhoneName;
