import MuiToolTip from '../../../components/tooltip'
import TransferIcon from '../../../assets/ic_transfer.svg'
import { capitalize, dateTimeFormating, formatCurrency, padLastFour } from '../../../utils/common-utils'
import Detail from '../Content/Cards/assets/ic_newDetails.svg'



export const prepareTransferData = (transfers: any) => {
  
  return transfers.map((item: any) => {
    const { beneficiaryCardLastFour, beneficiaryName , amount, currencyCode, transferType, transferDatetime } = item;
    
    const date = dateTimeFormating(transferDatetime, 'MMM DD YYYY');
    return {
      date : date || '-' , 
      name: beneficiaryName || '-',
      lastFour: beneficiaryCardLastFour ? '****' + beneficiaryCardLastFour :  '-',
      transferType: transferType ?  transferType : '-',
      amount: formatCurrency(amount, currencyCode)
    }
  })
}



