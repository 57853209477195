import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { formatCurrency, getCustomDate, showErrorNotification } from "../../../utils/common-utils";
import useLabels from "../../../hooks/use-labels";
import Loader from "../../../components/shared/loader/loader";
import MaterialDataTable from "../../../components/materialDataTable";
import { FC, useEffect, useState } from 'react'
import MaterialDateRangeFilter from "../../../components/material-daterange-filter";
import { DateProps } from "../../../interfaces/transaction";
import { fetchTransfer } from "../../../apis/b2c_api";
import { prepareTransferData } from "./transfer-history.utils";
const transferColumns = ['Date Time', 'Recipient Name', 'Card last 4', 'Type', 'Amount']

const TransferHistory = (props: any) => {
  const [dateRange, setDateRange] = useState<DateProps>({
    dateFrom: getCustomDate(new Date(), 6),
    dateTo: getCustomDate(new Date())
  });

  const [loading, setLoading] = useState(true);
  const [pageInformation, setPageInformation] = useState({
    pageNumber: 1,
    limit: 10,
  });
  // const [beneficiaryCardLastFour, setbeneficiaryCardLastFour] = useState('')
  const [transactions, setTransactions] = useState([]);


  const currentCardString = localStorage.getItem("currentCard");
  const card = currentCardString ? JSON.parse(currentCardString) : '';
  const { balance, cardId } = card;
  const { availableBalance, currencyCode } = balance;
  const {
    availableBalanceLabel,

  } = useLabels([
    "availableBalanceLabel",
    "addRecipientLabel",
    "chooseAccountLabel",
    "wantToTransferLabel",
    "searchPayeeLabel",
    "secondaryTransferLabel"
  ]);
  const applyDateFilter = (date?: DateProps, cardLast4?: any) => {
    date && setDateRange(date);
    const beneficiaryCardLastFour = cardLast4;
    fetchTransfeHistory(pageInformation, date, cardLast4);

  };

  useEffect(() => {
    fetchTransfeHistory(pageInformation, dateRange);
  }, [cardId])
  const fetchTransfeHistory = (
    { pageNumber, limit }: any,
    date?: DateProps,
    beneficiaryCardLastFour?: any
  ) => {
    const historyProps = {
      cardId,
      beneficiaryCardLastFour,
      offset: pageNumber,
      limit,
      ...(date && date.dateFrom && { dateFrom: date.dateFrom }),
      ...(date && date.dateTo && { dateTo: date.dateTo }),
    };
    setLoading(true);

    fetchTransfer(historyProps)
      .then((data) => {
        const { nodes, pageInfo } = data.transferHistory;

        const transactions = prepareTransferData(nodes);

        setTransactions(transactions);

        setPageInformation(() => ({ ...pageInfo, pageNumber, limit }));
        setLoading(false);
      })
      .catch(showErrorNotification);
  };
  const handlePageChange = (pageNumber: number) => {
    const pageDetails = { ...pageInformation, pageNumber };
    fetchTransfeHistory(pageDetails, dateRange);
  };

  const handleLimitChange = (limit: number) => {
    const pageDetails = { ...pageInformation, limit, pageNumber: 1 };
    fetchTransfeHistory(pageDetails, dateRange);
  };




  return (
    <>

      <Grid
        container
        columnSpacing={{ md: 12, xs: 6, sm: 12 }}
        rowSpacing={1}
        className="mb-4"
      >
        <Grid className="mt-3" item md={7} sm={12} xs={12}>

          <p className=" text-xs weight-400">

            {availableBalanceLabel}
          </p>
          <p className=" text-sm  weight-500">
            {formatCurrency(availableBalance, currencyCode)}
          </p>

        </Grid>
      </Grid>
      <div className="filter-box mt-3 mb-5">
        <MaterialDateRangeFilter
          applyDateFilter={applyDateFilter}
          defaultFromDate={dateRange.dateFrom}
          defaultToDate={dateRange.dateTo}
          showCardFilter={true}
        />
      </div>
      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <MaterialDataTable
          rows={transactions}
          columns={transferColumns}
          noDataText={'There are currently no transfers'}
          pageProps={{
            ...pageInformation,
            changePage: handlePageChange,
            changeLimit: handleLimitChange,
          }}
        />
      )}


    </>

  );
};

export default TransferHistory;
