import { useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getCardholder } from '../../../../apis/b2c_api'
import useLabels from '../../../../hooks/use-labels'
import { setCardholder } from '../../../../store/user/user-reducer'

import StepsView from '../../../signup-form/components/stepsView'
import SectionHeader from '../../../signup-form/header/section-header'
import ConfirmTransfer from './ConfirmTransfer'
import EnterAmount from './EnterAmount'
import TransactionReceipt from './TransactionReceipt'

interface TransferFundsModalContentProps {
  onClose: () => void
  recipient: any
  SecondaryTransfer: boolean
}

const TransferFundsModalContents = (props: TransferFundsModalContentProps) => {
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users)
  const dispatch = useDispatch()

  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({}) as any

  const { transactionReceiptLabel, confirmTransferLabel } = useLabels([
    'transactionReceiptLabel',
    'confirmTransferLabel'
  ])

  const handlePrevious = () => {
    setStep((previous) => previous - 1)
  }

  const handleNext = () => {
    setStep((previous) => previous + 1)
  }

  const onSuccessfulTransfer = () => {
    // const userCardholder = JSON.parse(JSON.stringify(cardholder));

    // const cards: any = JSON.parse(JSON.stringify(cardholder.cards))

    // const cardIndex = cards.findIndex(
    //   (cardItem: any) => cardItem.cardId === formData.account.cardId
    // )
    // const card = JSON.parse(JSON.stringify(cards[cardIndex]))
    
    // card.balance.availableBalance = +card.balance.availableBalance - +formData.amount
    // cards[cardIndex] = { ...card }
    // userCardholder.cards = [...cards]

    // dispatch(setCardholder(userCardholder))

    getCardholder()
      .then(({ cardholder }) => {
        dispatch(setCardholder(cardholder))
      })
  

    handleNext()
  }

  const onFormSubmit = (values: any) => {
    setFormData(values)
    handleNext()
  }

  const makeAnother = () => {
    setFormData({})
    setStep(1)
  }

  const componentSteps: any = {
    1: {
      title: confirmTransferLabel,
      component: () => (
        <EnterAmount
          onClose={props.onClose}
          onFormSubmit={onFormSubmit}
          formData={formData}
          recipient={props.recipient}
          SecondaryTransfer= {props.SecondaryTransfer}
        />
      )
    },
    2: {
      title: confirmTransferLabel,
      component: () => (
        <ConfirmTransfer
          handlePrevious={handlePrevious}
          onSuccessfulTransfer={onSuccessfulTransfer}
          {...props}
          {...formData}
          SecondaryTransfer= {props.SecondaryTransfer}
        />
      )
    },
    3: {
      title: transactionReceiptLabel,
      component: () => (
        <TransactionReceipt
          makeAnother={makeAnother}
          {...props}
          {...formData}
          SecondaryTransfer= {props.SecondaryTransfer}
        />
      )
    }
  }

  return (
    <div>
      <SectionHeader
        className="mat-modal-header"
        title={componentSteps[step].title}
      />
      <StepsView className={'mb-4'} listStep={step} numberofSteps={3} />
      {componentSteps[step].component()}
    </div>
  )
}
export default TransferFundsModalContents
