import moment from "moment";
import SignUpInput from "../interfaces/signup";
import parsePhoneNumber from "libphonenumber-js";
import { LoginFieldProps } from "../interfaces/formFields";
import { formatSocialSecurity, previewMaskSsn } from "./common-utils";

export const namePhoneFields = (fieldProps: any) => {
  const { firstNameLabel, lastNameLabel, phoneNumberLabel, nameLength } =
    fieldProps;
  return [
    {
      type: "text",

      elementProps: {
        name: "firstName",
        label: firstNameLabel,
        required: true,
        maxLength: nameLength,
      },
    },
    {
      type: "text",
      elementProps: {
        name: "lastName",
        label: lastNameLabel,
        required: true,
        maxLength: nameLength,
      },
    },
    {
      type: "phoneNumber",
      elementProps: {
        name: "phoneNumber",
        label: phoneNumberLabel,
        defaultCountry: "us",
        onlyCountries: ["us"],
        countryCodeEditable: false,
        required: true,
      },
    },
  ];
};

export const ssnDOBFields = (fieldProps: any) => {
  const { dobLabel, ssnLabel, confirmSsnLabel } = fieldProps;

  return [
    {
      type: "date",

      elementProps: {
        name: "dob",
        label: dobLabel,
        placeholder: "mm / dd / yyyy",
        format: "MM/DD/YYYY",
        required: true,
      },
    },
    {
      type: "ssnMasked",
      elementProps: {
        name: "ssn",
        label: ssnLabel,
        required: true,
        placeholder: "Enter digits only",
        maxLength: 9,
      },
    },
    {
      type: "confirmSsn",

      elementProps: {
        name: "confirmSsn",
        label: confirmSsnLabel,
        required: true,
        placeholder: "Enter digits only",
        maxLength: 9,
      },
    },
  ];
};

export const sideBarFields = () => {
  return [
    {
      type: "select",
      elementProps: {
        name: "Help",
        label: "Active",
        options: [{ value: "Active", name: "Active" }],
        valueKey: "value",
        nameKey: "name",
        elementHeight: "2rem",
        elementClass: "custom-select width-80",
        elementLabelClass: "custom-select-label",
      },
    },
  ];
};

// export const ssnFields = (fieldProps: any) => {
//   const { ssnLabel } = fieldProps;

//   // <input type="password" id="pwd" name="pwd" minlength="8">
//   return [
//     {
//       type: "password",

//       elementProps: {
//         name: "ssn",
//         label: ssnLabel,
//         required: true,
//         minLength: 9,
//         maxLength: 9,
//         mask: "999-99-9999",
//       },
//     },
//   ];
// };

export const personalAddressFields = (fieldProps: any) => {
  const {
    addressLabel,
    address2,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel,
    states,
    addressLength,
    cityLength,
  } = fieldProps;

  return [
    {
      type: "text",
      elementProps: {
        name: "address",
        label: addressLabel,
        required: true,
        maxLength: addressLength,
      },
    },
    {
      type: "text",
      elementProps: {
        name: "address2",
        label: address2,
        required: false,
        maxLength: addressLength,
      },
    },
    {
      multiple: true,
      type: "",
      name: "countryAndState",
      elementProps: { name: "countriesAndStates" },
      nestedFields: [
        {
          type: "text",
          elementProps: {
            name: "country",
            label: countryLabel,
            disabled: true,
            value: "USA",
            required: false,
          },
        },
        {
          type: "select",

          elementProps: {
            name: "region",
            label: stateLabel,
            placeholder: "Select State",
            options: states,
            nameKey: "name",
            valueKey: "code",
            required: true,
          },
        },
      ],
    },
    {
      multiple: true,
      type: "",
      name: "cityAndZipCode",
      elementProps: { name: "citiesAndZipCodes" },
      nestedFields: [
        {
          type: "text",
          elementProps: {
            name: "city",
            label: cityLabel,
            required: true,
            maxLength: cityLength,
          },
        },
        {
          type: "text",

          elementProps: {
            name: "postalCode",
            label: zipCodeLabel,
            required: true,
            maxLength: 15,
          },
        },
      ],
    },
  ];
};

export const accountFields = (fieldProps: any) => {
  const { emailLabel, passwordLabel, confirmPasswordLabel, passwordDisable } =
    fieldProps;
  return [
    {
      type: "email",
      dependantKey: "",
      elementProps: {
        name: "email",
        label: emailLabel,
        placeholder: "john.doe@gmail.com",
        disabled: true,
      },
    },
    {
      type: "password",
      dependantKey: "confirmPassword",
      elementProps: {
        name: "password",
        label: passwordLabel,
        required: true,
        disabled: passwordDisable,
      },
    },
    {
      type: "password",
      dependantKey: "password",
      elementProps: {
        name: "confirmPassword",
        label: confirmPasswordLabel,
        required: true,
        disabled: passwordDisable,
      },
    },
  ];
};

export const previewJSON = (values: SignUpInput, fieldProps: any) => {
  const {
    personalInformationTitle,
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    dobLabel,
    ssnLabel,
    identityTitle,
    countryLabel,
  } = fieldProps;

  return [
    {
      title: personalInformationTitle,
      content: [
        { label: firstNameLabel, value: values.firstName },
        { label: lastNameLabel, value: values.lastName },
        {
          label: phoneNumberLabel,
          value:
            parsePhoneNumber(values.phoneNumber, "US")?.formatInternational() ||
            "",
        },
      ],
    },
    {
      title: addressLabel,
      content: [
        { label: addressLabel, value: values.address },
        { label: countryLabel, value: values.country },
        { label: stateLabel, value: values.region },
        { label: cityLabel, value: values.city },
        { label: zipCodeLabel, value: values.postalCode },
      ],
    },
    {
      title: identityTitle,
      content: [
        { label: dobLabel, value: moment(values.dob).format("MM / DD / YYYY") },
        {
          label: ssnLabel,
          value: previewMaskSsn(formatSocialSecurity(values.ssn)),
        },
      ],
    },
  ];
};

export const loginFields = ({ emailLabel, passwordLabel }: LoginFieldProps) => {
  return [
    {
      type: "email",
      elementProps: {
        name: "email",
        label: emailLabel,
        required: true,
      },
    },
    {
      type: "password",
      elementProps: {
        name: "password",
        label: passwordLabel,
        required: true,
      },
    },
  ];
};

export const changePasswordFields = (fieldProps: any) => {
  const { oldPasswordLabel, newPasswordLabel, reTypePasswordLabel } =
    fieldProps;

  return [
    {
      type: "password",
      elementProps: {
        name: "oldPassword",
        label: oldPasswordLabel,
        required: true,
        placeholder: "Enter old password",
      },
    },
    {
      type: "password",
      elementProps: {
        name: "password",
        label: newPasswordLabel,
        required: true,
        placeholder: "Enter new password",
      },
    },
    {
      type: "password",
      elementProps: {
        name: "confirmPassword",
        label: reTypePasswordLabel,
        required: true,
        placeholder: "Re-type new password",
      },
    },
  ];
};

export const profileFields = (fieldProps: any) => {
  const {
    firstNameLabel,
    lastNameLabel,
    phoneNumberLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    countryLabel,
    firstName,
    lastName,
    countries,
    states,
    dobLabel,
    ssnLabel,
    dateOfBirth,
    ssn,
    labelColor,
    textColor,
  } = fieldProps;

  return {
    personalFields: [
      {
        type: "preview",

        elementProps: {
          labelClass: "weight-500",
          label: firstNameLabel,
          value: firstName,
          labelColor,
          textColor,
          name: "firstName preview",
          md: 3,
        },
      },
      {
        type: "preview",

        elementProps: {
          labelClass: "weight-500",
          label: lastNameLabel,
          value: lastName,
          labelColor,
          textColor,
          name: "lastName preview",
          md: 3,
        },
      },
      {
        type: "phoneNumber",
        elementProps: {
          name: "phoneNumber",
          label: phoneNumberLabel,
          defaultCountry: "us",
          onlyCountries: ["us"],
          countryCodeEditable: false,
          required: true,
          md: 6,
        },
      },
    ],

    addressFields: [
      {
        type: "text",
        elementProps: {
          name: "address",
          label: addressLabel,
          required: true,
        },
      },
      {
        type: "select",

        elementProps: {
          name: "country",
          label: countryLabel,
          placeholder: "Select Country",
          options: countries,
          nameKey: "name",
          valueKey: "code",
          required: true,
        },
      },
      {
        type: "select",

        elementProps: {
          name: "region",
          label: stateLabel,
          placeholder: "Select State",
          options: states,
          nameKey: "name",
          valueKey: "code",
          required: true,
        },
      },
      {
        type: "text",
        elementProps: {
          name: "city",
          label: cityLabel,
          required: true,
          maxLength: 25,
        },
      },
      {
        type: "text",

        elementProps: {
          name: "postalCode",
          label: zipCodeLabel,
          required: true,
          maxLength: 15,
        },
      },
    ],
    identityFields: [
      {
        type: "preview",

        elementProps: {
          labelClass: "weight-500",
          label: dobLabel,
          value: moment(dateOfBirth).format("MM / DD / YYYY"),
          labelColor,
          textColor,
          name: "dob preview",
        },
      },
      {
        type: "preview",

        elementProps: {
          labelClass: "weight-500",
          label: ssnLabel,
          value: ssn || "NA",
          labelColor,
          textColor,
          name: "ssn preview",
        },
      },
    ],
  };
};

export const secondaryCardFields = (fieldProps: any) => {
  const {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    mobileLabel,
    dobLabel,
    relationLabel,
    nameLength,
    editMode,
    relations,
    ssnLabel,
    countryLabel,
  } = fieldProps;

  return [
    {
      type: "text",

      elementProps: {
        name: "firstName",
        label: firstNameLabel,
        required: true,
        maxLength: nameLength,
        disabled: editMode,
      },
    },
    {
      type: "text",
      elementProps: {
        name: "lastName",
        label: lastNameLabel,
        required: true,
        maxLength: nameLength,
        disabled: editMode,
      },
    },
    {
      type: "email",
      dependantKey: "",
      elementProps: {
        name: "email",
        label: emailLabel,
        placeholder: "john.doe@gmail.com",
        disabled: editMode,
        required: true,
      },
    },
    {
      type: "phoneNumber",
      elementProps: {
        name: "phoneNumber",
        label: mobileLabel,
        defaultCountry: "us",
        onlyCountries: ["us"],
        countryCodeEditable: false,
        required: true,
        disabled: editMode,
      },
    },
    {
      type: "date",
      elementProps: {
        name: "dateOfBirth",
        label: dobLabel,
        placeholder: "mm / dd / yyyy",
        format: "MM/DD/YYYY",
        required: true,
        disabled: editMode,
      },
    },
    {
      type: "text",
      elementProps: {
        name: "country",
        label: countryLabel,
        disabled: true,
        value: "USA",
        required: false,
      },
    },
    {
      type: "ssnMasked",

      elementProps: {      
        name: "ssn",
        label: ssnLabel,
        required: true,
        placeholder: "Enter digits only",
        maxLength: 9,
      },
    },
    {
      type: "select",
      elementProps: {
        name: "relation",
        label: relationLabel,
        placeholder: "Select Relation",
        options: relations,
        nameKey: "name",
        valueKey: "value",
        required: true,
        disabled: editMode,
      },
    },
  ];
};

export const reviewSecondaryFields = (fieldProps: any) => {
  const {
    firstNameLabel,
    firstName,

    lastNameLabel,
    lastName,

    emailLabel,
    email,

    mobileLabel,
    phoneNumber,

    dobLabel,
    dateOfBirth,

    cardStateLabel,
    cardState,

    countryLabel,
    country,

    ssnLabel,
    ssn,

    relationLabel,
    relation,

    feeLabel,
    feeAmount,

    labelColor,
    textColor,
  } = fieldProps;

  return [
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: firstNameLabel,
        value: firstName,
        labelColor,
        textColor,
        name: "firstName preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: lastNameLabel,
        value: lastName,
        labelColor,
        textColor,
        name: "lastName preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: emailLabel,
        value: email,
        labelColor,
        textColor,
        name: "email preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: mobileLabel,
        value: phoneNumber,
        labelColor,
        textColor,
        name: "mobile preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: dobLabel,
        value: moment(dateOfBirth).format("MM / DD / YYYY"),
        labelColor,
        textColor,
        name: "dob preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: cardStateLabel,
        value: cardState,
        labelColor,
        textColor,
        name: "cardState preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: countryLabel,
        value: country,
        labelColor,
        textColor,
        name: "country preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: ssnLabel,
        value: previewMaskSsn(ssn),
        labelColor,
        textColor,
        name: "ssn preview",
        md: 6,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500",
        label: relationLabel,
        value: relation,
        labelColor,
        textColor,
        name: "relation preview",
        md: 12,
      },
    },
    {
      type: "preview",

      elementProps: {
        labelClass: "weight-500 mt-3",
        label: feeLabel,
        value: feeAmount,
        labelColor,
        textColor,
        name: "fee preview",
        md: 12,
      },
    }
  ];
};

export const setPasswordFields = (fieldProps: any) => {
  const {
    passwordLabel,
    confirmPasswordLabel,
    passwordPlaceholder,
    confirmPasswordPlaceholder,
  } = fieldProps;
  return [
    {
      type: "password",
      elementProps: {
        name: "password",
        label: passwordLabel,
        required: true,
        placeholder: passwordPlaceholder,
      },
    },
    {
      type: "password",
      elementProps: {
        name: "confirmPassword",
        label: confirmPasswordLabel,
        required: true,
        placeholder: confirmPasswordPlaceholder,
      },
    },
  ];
};
