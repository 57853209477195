import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import SectionHeader from "../../header/section-header";
import useConfigs from "../../../../hooks/use-config";
import FormButtons from "../../form-buttons";
import "./agreement-style.css";
import ScrollView from "../../../scroll-view";
import { showErrorNotification } from "../../../../utils/common-utils";
import DOMPurify from "dompurify";
import { getLegalAgreement } from "../../../../apis/b2c_api";
import Loader from "../../../shared/loader/loader";

const ESignAgreement = (props: any) => {
  const [isEsignAgree, setEsignAgreement] = useState(false);

  const agreements = props.legalAgreements;

  const { titleColor } = useStyles(["titleColor"]);
  const { eSignAgreementTitle, agreementDescription } = useLabels([
    "eSignAgreementTitle",
    "agreementDescription",
  ]);
  const { eSignCheckbox, agreement_error } = useConfigs([
    "eSignCheckbox",
    "agreement_error",
  ]);
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  
  return (
		<>
			<div className="cardholder-view">
				<SectionHeader
					title={eSignAgreementTitle}
					subTitle={agreementDescription}
				/>
				{!agreements?.length && <Loader className="custom-loader" />}
				{(agreements?.length && agreements.length > 0) && (
					<ScrollView
						onScrollToBottom={() => setDisableCheckbox(false)}
					>
						{agreements.map(
							({
								title,
								description,
								agreement_content,
							}: any) => {
								return title === "eSign" ? (
									<span
										key={title}
										style={{ color: titleColor }}
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(
												description ||
													agreement_content,
												{
													ADD_ATTR: ["target"],
												}
											),
										}}
									></span>
								) : null;
							}
						)}
					</ScrollView>
				)}
				<div
					className="mt-4 d-flex align-center agreement-section"
					style={{
						color: titleColor,
					}}
					onClick={() =>
						disableCheckbox
							? showErrorNotification(agreement_error)
							: undefined
					}
				>
					<Checkbox
						checked={isEsignAgree}
						style={{ padding: "unset", top: "-14px" }}
						onChange={({ target }) =>
							setEsignAgreement(target.checked)
						}
						size={"small"}
						disabled={disableCheckbox}
					/>
					<span className={`${disableCheckbox ? "text-muted" : ""}`}>
						{eSignCheckbox}
					</span>
				</div>
			</div>
			<FormButtons
				customClass={"mt-5"}
				handleNext={props.handleNext}
				handlePrevious={props.handlePrevious}
				nextButtonText={"Next"}
				disableNext={!isEsignAgree}
			/>
		</>
  );
};

export default ESignAgreement;
