import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents untyped JSON */
  JSON: any;
};

export type AddressInput = {
  address1: Scalars["String"];
  address2?: Maybe<Scalars["String"]>;
  addressType: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
  postalCode: Scalars["String"];
  region?: Maybe<Scalars["String"]>;
};

export type IdentificationDocInput = {
  additionalData?: Maybe<Scalars["JSON"]>;
  identificationDocumentUrl?: Maybe<Scalars["JSON"]>;
  identificationExpiryDate?: Maybe<Scalars["JSON"]>;
  identificationIssuanceCountry: Scalars["String"];
  identificationIssuanceRegion?: Maybe<Scalars["JSON"]>;
  identificationType: Scalars["String"];
  identificationValue: Scalars["String"];
};

export type PhoneDetailInput = {
  countryDialingCode: Scalars["Int"];
  phoneNumber: Scalars["String"];
  phoneType: Scalars["String"];
};

export type Cardholder = {
  addresses: [AddressInput];
  cardholderId?: Maybe<Scalars["ID"]>;
  citizenshipCountryCode: String;
  dateOfBirth: String;
  email?: Maybe<String>;
  firstName: String;
  gender: Maybe<String>;
  identificationDocs: [IdentificationDocInput];
  lastName: String;
  middleName?: Maybe<String>;
  phoneDetails: [PhoneDetailInput];
};

export type CreateCardholderPayload = {
  __typename?: "CreateCardHolder";
  cardholder: Cardholder;
  pendingOutOfWallet: PendingOutOfWallet;
};

export type Mutation = {
  __typename?: "Mutation";
  //  create a Cardholder
  createCardHolder?: Maybe<CreateCardholderPayload>;
};

export type MutationCreateCardHolderPayload = {
  addresses: [AddressInput];
  cardholderId?: Maybe<Scalars["ID"]>;
  citizenshipCountryCode: String;
  dateOfBirth: String;
  email?: Maybe<String>;
  externalRefNo: String;
  firstName: String;
  gender: Maybe<String>;
  identificationDocs: [IdentificationDocInput];
  lastName: String;
  middleName?: Maybe<String>;
  phoneDetails: [PhoneDetailInput];
  jwtToken: String;
};

export type PendingOutOfWallet = {
  cardholderId: String;
  failureReasons: [String];
  httpStatus: String;
  kycStatus: String;
  kycVendorResults: [KycVendorResults];
  message: String;
  oowQuestions: [OwwQuestion];
  responseCode: String;
  title: String;
};

export type KycVendorResults = {
  code: String;
  description: String;
  kycVendor: String;
  type: String;
};
export type OwwQuestion = {
  answers: [OwwAnswer];
  id: number;
  question: String;
};
export type OwwAnswer = {
  answer: String;
  id: number;
};

export const CREATE_CARD_HOLDER = `
mutation createCardholder (  $addresses: [AddressInput!]!, $citizenshipCountryCode: String!, $dateOfBirth: String!,$password:String!, $emailConfirmationToken: String!, $email: String, $firstName: String!, $gender: String, $identificationDocs: [IdentificationDocInput!]!, $jwtToken: String, $lastName: String!, $middleName: String, $newCardAttributes: CardInput, $phoneDetails: [PhoneDetailInput!], $primaryCardholderId: ID) {
  createCardholder (addresses: $addresses, citizenshipCountryCode: $citizenshipCountryCode, emailConfirmationToken: $emailConfirmationToken, dateOfBirth: $dateOfBirth, password:$password, email: $email, firstName: $firstName, gender: $gender, identificationDocs: $identificationDocs, jwtToken: $jwtToken, lastName: $lastName, middleName: $middleName, newCardAttributes: $newCardAttributes, phoneDetails: $phoneDetails, primaryCardholderId: $primaryCardholderId) {
      cardholder {
          addresses {
              address1
              address2
              addressType
              city
              country
              postalCode
              region
          }
          cardholderId
          cards {
              additionalData {
                  accountNumber
                  activationDate
                  aka
                  cardExpiration
                  cardholderId
                  designId
                  issueDate
                  key
                  processorToken
                  profession
                  routingNumber
              }
              availableBalance
              
              cardId
              
              cardholderId
              currencyCode
              externalRefNo
              lastFour
              nameOnCard
              physicalCardStatus
              primaryCardId
              programId
              programType
              shipmentInfo {
                  designId
                  shipmentDate
                  shipmentMethodInfo
                  trackingNumber
              }
              status           
          }
          citizenshipCountryCode
          dateOfBirth
          email
          externalRefNo
          firstName
          gender
          identificationDocs {
              identificationDocumentUrl
              identificationExpiryDate
              identificationIssuanceCountry
              identificationIssuanceRegion
              identificationType
              identificationValue
          }
          kycReasonCodes
          kycStatus
          lastName
          middleName
          phoneDetails {
              countryDialingCode
              phoneNumber
              phoneType
          }
      }
      pendingOutOfWallet {
            cardholderId
            failureReasons
            httpStatus
            kycStatus
            kycVendorResults {
                code
                description
                kycVendor
                type
            }
            message
            oowQuestions {
                answers {
                    answer
                    id
                }
                id
                question
            }
            responseCode
            title
        }
  }
}
`;

export const UPDATE_CARD_HOLDER = `
mutation updateCardHolder ($cardholderId:String!, $addresses: [AddressInput!]!, $dateOfBirth: String!, $firstName: String!, $lastName: String!, $phoneDetails: [PhoneDetailInput!]) {
  updateCardHolder (cardholderId:$cardholderId, addresses: $addresses, dateOfBirth: $dateOfBirth, firstName: $firstName,  lastName: $lastName, phoneDetails: $phoneDetails) {
      cardholder {
          addresses {
              address1
              address2
              addressType
              city
              country
              postalCode
              region
          }
          cardholderId
          cards {
              primaryCardId
              shipmentInfo{
                shipmentDate
                shipmentMethodInfo
                trackingNumber
                designId
              }
              additionalData {
                  accountNumber
                  activationDate
                  aka
                  cardExpiration
                  designId
                  issueDate
                  key
                  profession
                  routingNumber
                  cardholderId
              }
              cardholder {
                cardholderId
                citizenshipCountryCode
                dateOfBirth
                email
                externalRefNo
                firstName
                gender
                kycReasonCodes
                kycStatus
                lastName
                middleName
                phoneDetails {
                  countryDialingCode
                  phoneNumber
                  phoneType
              }
              identificationDocs {
                identificationDocumentUrl
                identificationExpiryDate
                identificationIssuanceCountry
                identificationIssuanceRegion
                identificationType
                identificationValue
            }
                addresses {
                  address1
                  address2
                  addressType
                  city
                  country
                  postalCode
                  region
                }
            }
            physicalCardStatus
              balance {
                  availableBalance
                  cardId
                  currencyCode
                  ledgerBalance
              }
              cardId
              cardholderId
              currencyCode
              externalRefNo
              lastFour
              nameOnCard
              programId
              status
          }
          citizenshipCountryCode
          dateOfBirth
          email
          externalRefNo
          firstName
          gender
          identificationDocs {
            identificationDocumentUrl
            identificationExpiryDate
            identificationIssuanceCountry
            identificationIssuanceRegion
            identificationType
            identificationValue
        }
          
          kycReasonCodes
          kycStatus
          lastName
          middleName
          phoneDetails {
              countryDialingCode
              phoneNumber
              phoneType
          }
      }
  }
}
`;

export const SUBMIT_OOWANSWERS = `mutation submitOowAnswers ($cardholderId: String!, $email: String!, $emailConfirmationToken: String!, $firstName: String!, $lastName: String!, $oowAnswers: [OwwAnswers!]!, $password: String!) {
  submitOowAnswers (cardholderId: $cardholderId, email: $email, emailConfirmationToken: $emailConfirmationToken, firstName: $firstName, lastName: $lastName, oowAnswers: $oowAnswers, password: $password) {
      verified
  }
}`;

export type CreateCardHolderMutationVariables = Exact<Cardholder>;

export type CreateCardHolderMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createCardHolder"
>;

export function UseCardHolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCardHolderMutation,
    CreateCardHolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCardHolderMutation,
    CreateCardHolderMutationVariables
  >(
    gql`
      ${CREATE_CARD_HOLDER}
    `,
    options
  );
}
