import { FC, useEffect, useState } from 'react'
import useLabels from '../../../hooks/use-labels'
import CloseIcon from '@mui/icons-material/Close';

import './card-modal-style.css'
import { getVirtualCard } from '../../../apis/b2c_api'
import Loader from '../../shared/loader/loader'

interface CardModalProps {
  onClose?: () => void
  cardId: string
  secureUrl?: string
}

const CardModalContent: FC<CardModalProps> = (props) => {
  const { onClose, cardId } = props
  const [secureUrl, setSecureUrl] = useState('')

  useEffect(() => {
    getVirtualCard(cardId).then(({ authInfo }) => {
      setSecureUrl(authInfo.secureUrl)
    })

    return () => {
      setSecureUrl('')
    }
  }, [setSecureUrl, cardId])

  const { cardModalTitle, closeLabel } = useLabels([
    'cardModalTitle',
    'closeLabel'
  ])


  return (
    <>

      <div className='mb-1 fullWidth text-right pr-10'>
        <CloseIcon className='pointer' sx={{ color: '#fff' }} onClick={onClose} />
      </div>
      
      <div className="mat-modal-body">
        {secureUrl ? (
          <iframe
            src={secureUrl}
            title="Virtual Card"
            className="modal-cardView"
          ></iframe>
        ) : (
          <Loader style={{ height: '9.8rem' }} />
        )}
      </div>
    </>
  )
}
export default CardModalContent
