import { Grid } from "@mui/material";
import moment from "moment";
import { useLocation } from "react-router";
import InformationHeader from "../../../../../../../components/information-header";
import useStyles from "../../../../../../../hooks/use-styles";
import {
  capitalize,
  dateTimeFormating,
  formatCurrency,
} from "../../../../../../../utils/common-utils";
import useLabels from "../../../../../../../hooks/use-labels";
import "./transaction-detail-style.css";

const TransactionDetail = (): JSX.Element => {
  const { titleColor, fontColor, labelColor, orangeColor } = useStyles([
    "titleColor",
    "fontColor",
    "labelColor",
    "orangeColor",
  ]);
  const {
    transactionAmountLabel,
    transactionDetailLabel,
    transactionDateLabel,
    transactionStatusLabel,
    transactionTypeLabel,
    transactionFeeLabel,
    merchantNameLabel,
    merchantCodeLabel,
    merchantCategoryLabel,
    merchantCityLabel,
    merchantCountryLabel,
    merchantRegionLabel,
    transactionIdLabel,
    transactionDescription,
  } = useLabels([
    "transactionAmountLabel",
    "transactionDetailLabel",
    "transactionDateLabel",
    "transactionStatusLabel",
    "transactionTypeLabel",
    "transactionFeeLabel",
    "merchantNameLabel",
    "merchantCodeLabel",
    "merchantCategoryLabel",
    "merchantCityLabel",
    "merchantCountryLabel",
    "merchantRegionLabel",
    "transactionIdLabel",
    "transactionDescription",
  ]);

  const { state } = useLocation();
  const { transaction }: any = state || {};

  const {
    transactionDatetime,
    transactionId,
    state: transacitonState,
    transactionType,
    description,
    amount,
    isoMessageType,
    currencyCode,
    additionalData: { feeAmount, memo, transactionImpact },
    merchant: {
      merchantName,
      mcc,
      mccGroup,
      merchantCountry,
      merchantCity,
      merchantRegion,
    },
  } = transaction;
  const preAuth = (isoMessageType == '0100' || isoMessageType == '0120') ? true : false;
  const merchantLocation = `${merchantCity || ''}${merchantRegion ? ', ' + merchantRegion : ''}${merchantCountry ? ', ' + merchantCountry : ''}`;
  // const transactionDetails = [
  //   {
  //     title: transactionDateLabel,
  //     value: dateTimeFormating(transactionDatetime, 'MMM DD YYYY, h:mm:ss a'),
  //   },
  //   { title: transactionStatusLabel, value: capitalize(transacitonState) },
  //   { title: transactionTypeLabel, value: capitalize(transactionType) },
  //   // {
  //   //   title: transactionFeeLabel,
  //   //   value: (
  //   //     <span style={{ color: orangeColor }}>
  //   //       {formatCurrency(feeAmount, currencyCode)}
  //   //     </span>
  //   //   ),
  //   // },
  //   { title: merchantNameLabel, value: merchantName || "-" },
  //   { title: merchantCountryLabel, value: merchantLocation || "-" },
  //   { title: transactionDescription, value: memo || description || "-" },
    
  // ];
  const transactionDetails = [
    {
      title: transactionDateLabel,
      value: dateTimeFormating(transactionDatetime, 'MMM DD YYYY, h:mm:ss a'),
    },
    { title: transactionStatusLabel, value: capitalize(transacitonState) },
    { title: transactionTypeLabel, value: capitalize(transactionType) },
    { title: merchantNameLabel, value: merchantName || "-" },
    { title: merchantCountryLabel, value: merchantLocation || "-" },
    { title: transactionDescription, value: description || memo || "-" },
  ];
  
  if (preAuth) {
    transactionDetails.splice(3, 0, 
      {
      title: transactionFeeLabel,
      value: (
        <span style={{ color: orangeColor }}>
          {formatCurrency(feeAmount, currencyCode)}
        </span>
      ),
    });
  }
  

  return (
    <div className="transaction-detail pt-2">
      <Grid container>
        <Grid item md={6} sm={6} xs={12}>
          <p className="label" style={{ color: labelColor }}>
            {transactionAmountLabel}
          </p>
          <p className="title weight-600" style={{ color: titleColor }}>
            {transactionImpact.toLowerCase() === 'credit' 
              ? `(${formatCurrency(amount, currencyCode)})`
              : `${formatCurrency(amount * -1, currencyCode)}`}
          </p>
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <div className="float-right transaction-detail-information">
            <p className="label" style={{ color: labelColor }}>
              {transactionIdLabel}
            </p>
            <p className="title weight-600" style={{ color: titleColor }}>
              {transactionId}
            </p>
          </div>
        </Grid>
      </Grid>

      <InformationHeader
        title={transactionDetailLabel}
        className="label weight-500 mt-4"
      />

      <Grid container className="mt-4" rowSpacing={3} columnSpacing={5}>
        {transactionDetails.map((item, index) => (
          <Grid key={item.value + index} item md={5} xs={12} sm={12}>
            <div
              className={`text-sm transaction-detail-item justify-between ${
                item.title === transactionDescription ? "d-flex" : "d-flex"
              }`}
            >
              <p className="weight-500" style={{ color: titleColor }}>
                {item.title}
              </p>
              <p style={{ color: fontColor ,wordWrap: "break-word"}}> {item.value}</p>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TransactionDetail;
