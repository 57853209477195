import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import FormField from "../../../../components/form/form-field";
import useLabels from "../../../../hooks/use-labels";
import useStyles from "../../../../hooks/use-styles";
import { useState } from "react";
import TransferFundsModal from "../../../../components/Modal/TransferFundsModal";
import { RootStateOrAny, useSelector } from "react-redux";
import { formatCurrency } from "../../../../utils/common-utils";
import TransferHistory from "../../TransferHistory/transferHistory";
import { useNavigate } from "react-router";

const RecipientHeader = (props: any) => {
  const { setFilteredRecipients, recipients } = props;
  const [searchText, setSearchText] = useState("");
  const [transfer, setTransfer] = useState(false);
  const [transferRecipient, setTransferRecipient] = useState(null) as any;
  const { cardholder } = useSelector((state: RootStateOrAny) => state.users);
  const navigate = useNavigate();
  const { primaryCardholderId } = cardholder;
  const currentCardString = localStorage.getItem("currentCard");
  const card = currentCardString ? JSON.parse(currentCardString) : '';
  const { balance } = card;
  const { availableBalance, currencyCode } = balance;

  const {
    availableBalanceLabel,
    addRecipientLabel,
    chooseAccountLabel,
    wantToTransferLabel,
    searchPayeeLabel,
    secondaryTransferLabel
  } = useLabels([
    "availableBalanceLabel",
    "addRecipientLabel",
    "chooseAccountLabel",
    "wantToTransferLabel",
    "searchPayeeLabel",
    "secondaryTransferLabel"
  ]);
  const { lightBlueColor } = useStyles(["lightBlueColor"]);

  const onSearch = (value: string) => {
    const filteredRecipients = recipients.filter((item: any) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(value.toLowerCase())
      );
    });

    setSearchText(value);
    setFilteredRecipients(value.trim() ? filteredRecipients : null);
  };
  const toggleTransfer = (recipient?: any) => {
    setTransfer((previouseValue) => !previouseValue);
    setTransferRecipient(recipient || null);
  };

  const renderTransferFundsModal = () => {
    if (!transfer) return null;

    return (
      <TransferFundsModal
        isOpen={transfer}
        onClose={() => toggleTransfer()}
        recipient={transferRecipient}
        SecondaryTransfer={true}
      />
    );
  };



  const handleTransferHistory= () => {
    navigate("/transfer-history", { state: { pageCrumbs: [{ title: "Transfer History" }] } });
  };



  return (
    <>
      {renderTransferFundsModal()}

      <Grid
        container
        columnSpacing={{ md: 12, xs: 6, sm: 12 }}
        rowSpacing={1}
        className="mb-4"
      >
        <Grid item md={7} sm={12} xs={12}>
          <p className="weight-400">
            <span className="weight-500">{chooseAccountLabel}</span>{" "}
            {wantToTransferLabel}
          </p>
        </Grid>

        <Grid item md={5} sm={12} xs={12}>

          {/* {!primaryCardholderId && (
            <p
              className="pointer text-sm width-fit-content float-right"
              style={{ color: lightBlueColor, paddingRight: `20px` }}
              onClick={() => toggleTransfer()}
            >
              {secondaryTransferLabel} <AddIcon />
            </p>
          )} */}


        </Grid>


        <Grid className="mt-3" item md={7} sm={12} xs={12}>

          <p className=" text-xs weight-400">

            {availableBalanceLabel}
          </p>
          <p className=" text-sm  weight-500">
            {formatCurrency(availableBalance, currencyCode)}
          </p>

        </Grid>
        <Grid  className="mt-3" item md={5} sm={12} xs={12}>
        <p
              className="pointer text-xs width-fit-content float-right"
              style={{ color: lightBlueColor, paddingRight: `20px` }}
              onClick={() => handleTransferHistory()}
            >
              {"Transfer History"} 
            </p>


        </Grid>

      </Grid>

    </>
   
  );
};

export default RecipientHeader;
