import SectionHeader from "../../header/section-header";
import supportImage from "../../assets/contactSupport.svg";
import errorImage from "../../../../assets/ic_error.svg";
import successImage from "../../../../assets/ic_success.svg";

import useStyles from "../../../../hooks/use-styles";
import CustomButton from "../../../custom-button";
import "./error-style.css";
import useLabels from "../../../../hooks/use-labels";
import { useNavigate } from "react-router";

const SignupError = (props: any) => {
  const { tokenError, message } = props;
  const navigate = useNavigate();
  const handleSignupClick = () => {
    window.location.href = "https://login.my529.org/payments";
  };

  const { titleColor, subTitleColor } = useStyles([
    "titleColor",
    "subTitleColor",
  ]);
  const {
    ContactSupportText,
    AnyQuestionText,
    ErrorPageDescText,
    ErrorPageHeadingText,
  } = useLabels([
    "ContactSupportText",
    "AnyQuestionText",
    "ErrorPageDescText",
    "ErrorPageHeadingText",
  ]);

  return (
    <div className="d-flex-center error-page">
      {tokenError === "Token is Expired." ? (
        <>
          <img
            className="mb-4"
            src={errorImage}
            alt="errorImage"
            style={{ width: "auto" }}
          />

          <p className="text-md mb-4" style={{ color: titleColor }}>
            Your application could not be processed at this time. Please click
            sign up to try again
          </p>

          <CustomButton
            text={"Sign Up"}
            type="primary"
            style={{ width: "30%" }}
            onClick={handleSignupClick}
          />
        </>
      ) : tokenError === "Account approved" ? (
        <>
          <img
            className="mb-4"
            src={successImage}
            alt="successImage"
            style={{ width: "auto" }}
          />

          <p className="text-md mb-2" style={{ color: titleColor }}>
            Congratulations
          </p>

          <p className="text-sm mb-4" style={{ color: subTitleColor }}>
            Your request has been approved.
          </p>

          <CustomButton
            text={"Login"}
            type="primary"
            style={{ width: "30%" }}
            onClick={() => navigate("/login")}
          />
        </>
      ) : (
        <>
          <SectionHeader title={ErrorPageHeadingText} />
          <div className="error-content mb-4">
            <p className="text-sm mt-1" style={{ color: subTitleColor }}>
              {ErrorPageDescText}
            </p>
          </div>
          <img
            className="header-img mb-5"
            src={supportImage}
            alt="supportImage"
          />
          <div>
            <p className="text-sm " style={{ color: subTitleColor }}>
              {AnyQuestionText}
            </p>
            <p className="text-sm pointer " style={{ color: titleColor }}>
              {ContactSupportText}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupError;
