import React from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import AdapterMoment from "@mui/lab/AdapterMoment";
import InputMask from "react-input-mask";
import useStyles from "../../../hooks/use-styles";

import { findErrorMessage } from "../../../utils/metadata";
import { getLanguage } from "../../../utils/language-helper";
import "./field-style.css";
import { PreviewContent } from "../../signup-form/components/detailsPreview/preview-content";

const RenderField = (props: any) => {
  const {
    onChange,
    type,
    textColor,
    borderColor,
    value,
    labelColor,
    error,
    elementProps,
    fieldMessage,
  } = props;

  const errorMessage = findErrorMessage(error, getLanguage());
  const fieldProps = {
    className: "form__field",
    error: !!error,
    helperText: errorMessage || fieldMessage || "",
    FormHelperTextProps: { style: { fontSize: ".7rem" } },
    inputProps: {
      style: {
        color: textColor,
        borderBottom: `1px solid ${borderColor}`,
        fontSize: ".9rem",
      },
      ...(elementProps.maxLength && { maxLength: elementProps.maxLength }),
    },
    InputLabelProps: {
      shrink: true,
      style: { color: labelColor, fontWeight: 600 },
    },
    value,
  };

  switch (type) {
    case "text":
    case "number":
    case "email":
    case "password":
      return (
        <TextField
          {...fieldProps}
          {...elementProps}
          variant="standard"
          type={type}
          onChange={({ target }) => onChange(elementProps.name, target.value)}
        />
      );

    case "ssnMasked":
      return (
        <TextField
          {...fieldProps}
          {...elementProps}
          // inputProps={{type: 'number'}}
          value={props.maskedSSN}
          variant="standard"
          onChange={(event) => onChange(event)}
          id="outlined-number"
        />
      );

    case "confirmSsn":
      return (
          <TextField
              {...fieldProps}
              {...elementProps}
              // inputProps={{type: 'number'}}
              value={props.maskedConfirmSSN}
              variant="standard"
              onChange={(event) => onChange(event)}
              id="outlined-number"
          />
      );

    case "date":
      delete fieldProps.value;
      delete fieldProps.inputProps;

      const { format, placeholder, ...otherProps } = elementProps;
      const { className, ...otherFieldProps } = fieldProps;

      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            renderInput={({ inputProps, ...params }) => {
              return (
                <TextField
                  size="small"
                  variant="standard"
                  {...params}
                  {...otherFieldProps}
                  {...otherProps}
                  className={`${className} date-field`}
                  value={value ? moment(value).format(format) : ""}
                  inputProps={{
                    ...inputProps,
                    placeholder,
                    style: { color: textColor, fontSize: ".9rem" },
                  }}
                />
              );
            }}
            openTo="day"
            views={["year", "month", "day"]}
            disableFuture
            value={value ? moment(value).format(format) : null}
            onChange={(value) => onChange(elementProps.name, value)}
            disabled={elementProps.disabled}
          />
        </LocalizationProvider>
      );

    case "select":
      return (
        <TextField
          select
          {...fieldProps}
          name={elementProps.name}
          label={elementProps.label}
          disabled={elementProps.disabled}
          required={elementProps.required}
          SelectProps={{ style: { fontSize: ".9rem" } }}
          variant="standard"
          id="outlined-select"
          onChange={({ target }) => onChange(elementProps.name, target.value)}
        >
          {elementProps.options.map((optionItem: any, index: number) => (
            <MenuItem
              className="mui-menu-item"
              key={`${optionItem.value}${elementProps.name}${index}`}
              value={optionItem[elementProps.valueKey]}
            >
              {optionItem[elementProps.nameKey]}
            </MenuItem>
          ))}
        </TextField>
      );

    case "phoneNumber":
      return (
        <MuiPhoneNumber
          {...fieldProps}
          {...elementProps}
          sx={{ '& svg': { height: '1em', }, }}
          onChange={(value) => onChange(elementProps.name, value)}
        />
      );

    case "mask":
      return (
        <InputMask
          mask={elementProps.mask}
          value={value}
          onChange={({ target }) => onChange(elementProps.name, target.value)}
        >
          {() => (
            <TextField
              {...fieldProps}
              {...elementProps}
              variant="standard"
              type={type}
              id="outlined-mask"
            />
          )}
        </InputMask>
      );

    case "checkbox":
      const { className: formControlClass, name, size, ...rest } = elementProps;
      return (
        <FormControlLabel
          className={`fieldControlLabel ${formControlClass!}`}
          control={
            <Checkbox
              className="field-checkbox"
              name={name}
              size={size || "default"}
              defaultChecked={!!value}
              onChange={({ target }) =>
                onChange(elementProps.name, target.checked)
              }
            />
          }
          {...rest}
        />
      );

    case "preview":
      return <PreviewContent {...elementProps} />;

    case "search":
      const { onSearch, ...restElementProps } = elementProps;
      return (
        <>
          <Paper
            component="form"
            className="transparent-background text-sm d-flex align-center"
            sx={{
              p: "2px 4px",
              boxShadow: "unset",
              borderBottom: `1px solid ${!error ? borderColor : "#d32f2f"}`,
              borderRadius: "unset",
            }}
          >
            <InputBase
              {...restElementProps}
              className="text-sm"
              sx={{ flex: 1, color: textColor }}
              autoFocus={false}
              endAdornment={false}
              type={type}
              onChange={({ target }) =>
                onChange(elementProps.name, target.value)
              }
              error={!!error}
            />

            <IconButton
              onClick={onSearch}
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </Paper>
          {errorMessage && (
            <p className="text-xs" style={{ color: "#d32f2f" }}>
              {errorMessage}
            </p>
          )}
        </>
      );
    default:
      return <></>;
  }
};

const FormField = (props: any) => {
  const { textColor, borderColor, labelColor } = useStyles([
    "textColor",
    "borderColor",
    "labelColor",
  ]);

  return (
    <div
      className={`field-container ${props.className!}`}
      style={{ color: textColor }}
    >
      <RenderField
        {...props}
        textColor={textColor}
        borderColor={borderColor}
        labelColor={labelColor}
      />
    </div>
  );
};

export default FormField;
