import { FC, useEffect, useState } from 'react'
import { getChangePinWidget } from '../../../../apis/b2c_api'
import useLabels from '../../../../hooks/use-labels'
import { showErrorNotification } from '../../../../utils/common-utils'
import CustomButton from '../../../custom-button'
import Loader from '../../../shared/loader/loader'
import SectionHeader from '../../../signup-form/header/section-header'
import CloseIcon from '@mui/icons-material/Close';
import './change-pin-style.css'

interface ChangePinModalContentProps {
  actionProps: any
  onClose: () => void
}

const ChangePinModalContent: FC<ChangePinModalContentProps> = ({
  actionProps,
  onClose
}) => {
  const [secureUrl, setSecureUrl] = useState('')
  const { cardId } = actionProps

  useEffect(() => {
    getChangePinWidget(cardId).then(({ pinChangeUrl }) => {
      setSecureUrl(pinChangeUrl.secureUrl)
    }).catch(showErrorNotification)

    return () => {
      setSecureUrl('')
    }
  }, [setSecureUrl, cardId])

  const { changePinModalTitle, closeLabel } = useLabels([
    'changePinModalTitle',
    'closeLabel'
  ])


  return (
    <>
      <div className='mb-3 fullWidth text-right'>
        <CloseIcon className='pointer' sx={{ color: '#fff' }} onClick={onClose} />
      </div>

      {!secureUrl ? (
        <Loader className="custom-loader" />
      ) : (
        <iframe
          src={secureUrl}
          title="Change Pin Widget"
          className="modal-pin-change-view"
        />
      )}

    </>
  )
}

export default ChangePinModalContent
