import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  downloadStatement,
  fetchAccountStatements
} from '../../../../../apis/b2c_api'
import { showErrorNotification } from '../../../../../utils/common-utils'
import MaterialDataTable from '../../../../materialDataTable'
import Loader from '../../../../shared/loader/loader'
import DownloadImage from '../../../../../assets/ic_download.svg'

import useLabels from '../../../../../hooks/use-labels'

interface TransactionStatementProps {
  cardId: string
}

const columns = ['Sr.', 'Report Month', 'Generated Date', 'Period', `Download`]

const TransactionStatements: FC<TransactionStatementProps> = ({ cardId }) => {
  const [accountStatements, setStatements] = useState([])
  const [loading, setLoader] = useState(false)
  const { noStatementText } = useLabels(['noStatementText'])

  const onDownloadClick = (statementId: string) => {
    setLoader(true)
    downloadStatement(statementId)
      .then(({ statementLink }) => {
        setLoader(false)
        window.open(statementLink, '_blank')
      })
      .catch((error) => {
        setLoader(false)
        showErrorNotification(error)
      })
  }

  useEffect(() => {
    setLoader(true)

    fetchAccountStatements(cardId)
      .then(({ statements }) => {
        const preparedStatement = statements.map(
          (item: any, index: number) => ({
            serial: index + 1,
            reportMonth: moment(item.startDate).format('MMMM'),
            generatedDate: moment(item.requestedOn).format('MMM DD, YYYY'),
            period: (
              <span>
                {item.startDate} <ArrowRightAltIcon fontSize='small'/> {item.endDate}
              </span>
            ),
            download: (
              <img
                className="pointer ml-1 mr-1"
                src={DownloadImage}
                alt="download"
                width={27}
                height={27}
                onClick={() => onDownloadClick(item.id)}
              />
            )
          })
        )

        setStatements(preparedStatement)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        showErrorNotification(error)
      })
  }, [cardId])

  return (
    <>
      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <MaterialDataTable
          rows={accountStatements}
          columns={columns}
          noDataText={noStatementText}
          noDataStyle={{ transform: 'translateX(-50%)', width: 'fit-content' }}
          scrollableHeight={accountStatements.length ? 280 : '100%'}
        />
      )}
    </>
  )
}

export default TransactionStatements
