import React, {useEffect, useState} from "react";
import Loader from "./components/shared/loader/loader";
import "./App.css";
import "./Bootstrap.css";
import {setMetadata} from "./utils/metadata";
import {changeLanguage} from "./utils/language-helper";
import AppContainer from "./views/AppContainer";
import {fetchMetaData} from "./apis/b2c_api";

const setLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    const languageCode = params.get("language_code") || "en";
    changeLanguage(languageCode);
};

const App = (): JSX.Element => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLanguage();
        fetchMetaData()
            .then(metadata => {
                setMetadata(metadata?.metadata ? JSON.parse(metadata.metadata) : {});
                setLoading(false);
            })
            .catch(console.error);
    }, []);

    if (loading) return <Loader/>;
    return (
        <div className="App Bootstrap">
            <AppContainer/>
        </div>
    );
};

export default App;
